import React, { useState } from 'react';
import { initializeApp } from 'firebase/app'; 
import MenuScreen from './components/MenuScreen';
import UpdateDish from './components/UpdateDish';
import UpdateMenu from './components/UpdateMenu';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const firebaseApp = initializeApp(firebaseConfig);

const App = () => {
  const [showUpdateDish, setShowUpdateDish] = useState(false);
  const [showUpdateMenu, setShowUpdateMenu] = useState(false);

  const handleUpdateDishClick = () => {
    setShowUpdateDish(true);
  };

  const handleUpdateMenuClick = () => {
    setShowUpdateMenu(true);
  };

  const handleBackToMenu = () => {
    setShowUpdateDish(false);
    setShowUpdateMenu(false);
  };

  return (
    <div>
      {showUpdateDish ? (
        <UpdateDish onBack={handleBackToMenu} />
      ) : showUpdateMenu ? (
        <UpdateMenu onBack={handleBackToMenu} />
      ) : (
        <MenuScreen 
          onUpdateDishClick={handleUpdateDishClick}
          onUpdateMenuClick={handleUpdateMenuClick}
        />
      )}
    </div>
  );
}

export default App;
