import React, { useState, useEffect } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import logoImage from '../assets/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faEdit, faTrashAlt, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import '../styles/CardapioDia.css';
import '../styles/Header.css';
import '../styles/MenuBar.css';
import '../styles/MenuScreen.css';
import '../styles/SideBar.css';
import EditPratoForm from './EditPratoForm';

const MenuScreen = ({ onUpdateDishClick, onUpdateMenuClick }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [pratos, setPratos] = useState([]);
  const [authorizedUser, setAuthorizedUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [editingPratoId, setEditingPratoId] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [userName, setUserName] = useState('');

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const formatDate = (date) => {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    if (date.toDateString() === today.toDateString()) {
      return "Hoje";
    } else if (date.toDateString() === tomorrow.toDateString()) {
      return "Amanhã";
    } else {
      return date.toLocaleDateString('pt-BR');
    }
  };

  const handleLogin = async () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      setUser(user);
      checkUserAuthorization(user.email);
    } catch (error) {
      console.error('Erro ao fazer login:', error.message);
    }
  };

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      setUser(null);
      setAuthorizedUser(false);
    } catch (error) {
      console.error('Erro ao fazer logout:', error.message);
    }
  };

  const checkUserAuthorization = async (email) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/authorizedUsers/${email}`);
      const data = await response.json();
      setAuthorizedUser(data.authorized);
      setLoading(false);
    } catch (error) {
      console.error('Erro ao verificar usuário autorizado:', error);
    }
  };

  const handlePreviousDate = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() - 1);
    setCurrentDate(newDate);
  };

  const handleNextDate = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + 1);
    setCurrentDate(newDate);
  };

  const fetchPratos = async (date) => {
    try {
      const formattedDate = new Date(date).toISOString().split('T')[0]; // Formata a data para o formato YYYY-MM-DD
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/menu/date/${formattedDate}`);
      const data = await response.json();
      setPratos(data.pratos || []); // Atualiza o estado com a lista de pratos
    } catch (error) {
      console.error('Erro ao buscar pratos:', error);
    }
  };
  

  useEffect(() => {
    fetchPratos(currentDate);

    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        checkUserAuthorization(user.email);
        setUserName(user.displayName);
      } else {
        setUser(null);
        setUserName(null);
      }
    });

    return () => unsubscribe();
  }, [currentDate]);

  // const handleEditPrato = (id) => {
  //   setEditingPratoId(id);
  // };

  // const handleExcluirPrato = (index) => {
  //   const novosPratos = [...pratosArray];
  //   novosPratos.splice(index, 1); // Remove o prato do array
  //   setPratosArray(novosPratos);
  // };


  return (
    <div className="menu-screen no-select">
      <div className="header">
        <img src={logoImage} alt="Logo" className="logo" />
        <FontAwesomeIcon icon={faBars} className="btn-header" onClick={toggleSidebar} />
      </div>

      <div className={`overlay ${isSidebarOpen ? 'open' : ''}`} onClick={toggleSidebar}></div>

      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <div>
          {userName ? (
            <h1>Bem-vindo, {userName}!</h1>
          ) : (
            <h1>Carregando...</h1>
          )}
        </div>
        <FontAwesomeIcon icon={faTimes} className="btn-x" onClick={toggleSidebar} />
        <ul>
          {authorizedUser && (
            <>
              <li className="btn-slide" onClick={onUpdateDishClick}>Adicionar Prato</li>
              <li className="btn-slide" onClick={onUpdateMenuClick}>Adicionar Cardápio</li>
            </>
          )}
          <li className="btn-slide" onClick={user ? handleLogout : handleLogin}>
            {user ? 'Logout' : 'Login'}
          </li>
        </ul>
      </div>

      <div className="menu-bar">
        <div className="menu-item nav-item" onClick={handlePreviousDate}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>
        <div>{formatDate(currentDate)}</div>
        <div className="menu-item nav-item" onClick={handleNextDate}>
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      </div>

      <div className="pratos-list-container">
        {pratos.length > 0 ? (
          <div className="pratos-container">
            {pratos.map((prato) => (
              <div key={prato._id} className="prato-item">
                <div className="nome">{prato.nome}</div>
                <div className="descricao">{prato.descricao}</div>
                {/* {authorizedUser && (
                  <div className="icons">
                    <span className="edit-icon" onClick={() => handleEditPrato(prato._id)}>
                      <FontAwesomeIcon icon={faEdit} />
                    </span>
                    <span className="delete-icon" onClick={() => handleExcluirPrato(index)}>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </span>
                  </div>
                )} */}
              </div>
            ))}
          </div>
        ) : (
          <div className="no-item">Não há pratos disponíveis para esta data</div>
        )}
      </div>
    </div>
  );
};

export default MenuScreen;