import React, { useState } from 'react';
import '../styles/EditPratoForm.css';


const EditPratoForm = ({ prato, onUpdate, onCancel }) => {
  const [nome, setNome] = useState(prato.nome);
  const [descricao, setDescricao] = useState(prato.descricao);

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdate(prato._id, { nome, descricao });
  };

  return (
    <div className="edit-prato-form-container">
      <form className="edit-prato-form" onSubmit={handleSubmit}>
        <div className="input-group">
          <label className="label" htmlFor="nome">Nome do prato:</label>
          <input
            type="text"
            id="nome"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            className="input-field"
            placeholder="Nome do prato"
            required
          />
        </div>
        <div className="input-group">
          <label className="label" htmlFor="descricao">Descrição do prato:</label>
          <textarea
            id="descricao"
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
            className="input-field"
            placeholder="Descrição do prato"
          />
        </div>
        <div className="button-group">
          <button type="submit" className="button">Atualizar</button>
          <button type="button" className="button" onClick={onCancel}>Cancelar</button>
        </div>
      </form>
    </div>
  );
};

export default EditPratoForm;
