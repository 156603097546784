import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons'; // Importa ícones usados no aplicativo
import logoImage from '../assets/logo.png'; // Importa a imagem do logo
import '../styles/UpdateDish.css'; // Importa o CSS específico para a tela de atualização do menu
import '../styles/CardapioDia.css'; // Importa o CSS para a tela de cardápio do dia
import '../styles/Header.css'; // Importa o CSS para o cabeçalho
import '../styles/MenuBar.css'; // Importa o CSS para a barra de menu

const UpdateDish = ({ onBack }) => {
    // Declara variáveis de estado para armazenar dados da tela
    const [nomePrato, setNomePrato] = useState(''); // Nome do prato a ser adicionado
    const [descricaoPrato, setDescricaoPrato] = useState(''); // Descrição do prato
    const [cardapios, setCardapios] = useState([]); // Lista de pratos adicionados

    useEffect(() => {
        const fetchPratos = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/dish`);
                if (!response.ok) {
                    throw new Error('Erro ao buscar pratos');
                }
                const pratos = await response.json();
                setCardapios(pratos); // Atualiza a lista de pratos
            } catch (error) {
                console.error('Erro ao buscar pratos:', error);
            }
        };

        fetchPratos(); // Chama a função quando o componente for montado
    }, []); // O array vazio [] garante que o fetch seja feito apenas uma vez


    // Função para adicionar um novo prato
    const handleAdicionarPrato = async () => {
        try {
            // Cria um novo prato com o nome, a descrição e likes inicializados em 0
            const novoPrato = { nome: nomePrato, descricao: descricaoPrato, likes: 0 };
            
            // Envia a solicitação POST para adicionar o prato na coleção 'dish'
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/dish`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(novoPrato)
            });
            
            if (!response.ok) {
                throw new Error('Erro ao adicionar prato');
            }
            
            // Atualiza a lista de pratos com o prato adicionado
            const pratoAdicionado = await response.json();
            setCardapios([...cardapios, pratoAdicionado]);
            setNomePrato(''); // Limpa o campo de nome
            setDescricaoPrato(''); // Limpa o campo de descrição
        } catch (error) {
            console.error('Erro ao adicionar prato:', error);
        }
    };

    // Função para excluir um prato
    const handleExcluirPrato = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/dish/${id}`, {
                method: 'DELETE'
            });
            if (!response.ok) {
                throw new Error('Erro ao excluir prato');
            }
            // Remove o prato excluído da lista
            setCardapios(cardapios.filter(prato => prato._id !== id));
        } catch (error) {
            console.error('Erro ao excluir prato:', error);
        }
    };



    return (
        <div className="update-menu-screen">
            {/* Cabeçalho com logo e botão de voltar */}
            <div className="header">
                <img src={logoImage} alt="Logo" className="logo" />
                <button className="btn-back" onClick={onBack}>
                    Voltar
                </button>
            </div>
            
            <div className="container">
                <div className="form">
                    {/* Campos de entrada para nome e descrição do prato */}
                    <input
                        type="text"
                        placeholder="Nome do Prato"
                        value={nomePrato}
                        onChange={(e) => setNomePrato(e.target.value)}
                    />
                    <textarea
                        placeholder="Descrição do Prato"
                        value={descricaoPrato}
                        onChange={(e) => setDescricaoPrato(e.target.value)}
                    ></textarea>
                    
                    {/* Botão para adicionar o prato */}
                    <div className="btn-enviar">
                        <button className="btn-add-lunch" onClick={handleAdicionarPrato}>Adicionar Prato</button>
                    </div>
                </div>
                
                {/* Lista de pratos adicionados */}
                <div className="cardapios-list">
                    <h3>Pratos Adicionados</h3>
                    <ul>
                        {cardapios.map((prato) => (
                            <li key={prato._id}>
                                <span>{prato.nome}</span>
                                <span className="descricao">{prato.descricao}</span>
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    className="delete-icon"
                                    onClick={() => handleExcluirPrato(prato._id)} 
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default UpdateDish;
