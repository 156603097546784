import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import logoImage from '../assets/logo.png';
import '../styles/UpdateDish.css';
import '../styles/UpdateMenu.css';
import '../styles/CardapioDia.css';
import '../styles/Header.css';
import '../styles/MenuBar.css';

const UpdateMenu = ({ onBack }) => {
  const [nomePrato, setNomePrato] = useState('');
  const [descricaoPrato, setDescricaoPrato] = useState('');
  const [dataSelecionada, setDataSelecionada] = useState(new Date());
  const [cardapios, setCardapios] = useState([]);
  const [pratosArray, setPratosArray] = useState([]); // Array para pratos adicionados
  const [pratosDisponiveis, setPratosDisponiveis] = useState([]);
  const [showPratoList, setShowPratoList] = useState(false);
  const [pratos, setPratos] = useState([]);

  /*
    useEffect(() => {
      fetchPratos(currentDate);
  
      const auth = getAuth();
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          setUser(user);
          checkUserAuthorization(user.email);
          setUserName(user.displayName);
        } else {
          setUser(null);
          setUserName(null);
        }
      });
  
      return () => unsubscribe();
    }, [currentDate]);
  */

  useEffect(() => {
    const fetchPratos = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/dish`);
        if (!response.ok) {
          throw new Error('Erro ao buscar pratos');
        }
        const pratos = await response.json();
        setPratosDisponiveis(pratos);
      } catch (error) {
        console.error('Erro ao buscar pratos:', error);
      }
    };


    /*
        const fetchPratos = async (date) => {
          try {
            const formattedDate = new Date(date).toISOString().split('T')[0]; // Formata a data para o formato YYYY-MM-DD
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/menu/date/${formattedDate}`);
            const data = await response.json();
            setPratos(data.pratos || []); // Atualiza o estado com a lista de pratos
          } catch (error) {
            console.error('Erro ao buscar pratos:', error);
          }
        };
        */




    const fetchCardapio = async () => {
      try {
        const dataFormatada = new Date(dataSelecionada).toISOString().split('T')[0];
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/menu?date=${dataFormatada}`);
        if (!response.ok) {
          throw new Error('Erro ao buscar cardápio');
        }
        const menu = await response.json();
        if (menu) {
          setPratosArray(menu.pratos || []);
        } else {
          setPratosArray([]); // Se não houver cardápio, inicializar com array vazio
        }
      } catch (error) {
        console.error('Erro ao buscar cardápio:', error);
      }
    };

    fetchPratos();
    fetchCardapio();
  }, [dataSelecionada]);

  const handleAdicionarMenu = async () => {
    try {
      if (!dataSelecionada) {
        throw new Error('Preencha todos os campos.');
      }

      const dataFormatada = new Date(dataSelecionada).toISOString().split('T')[0];

      // Verificar se já existe um cardápio para a data selecionada
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/menu/date/${dataFormatada}`);
      if (response.ok) {
        // Se o cardápio existir, fazer uma atualização (PUT)
        const novoMenu = { pratos: pratosArray };
        const updateResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/menu/date/${dataFormatada}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(novoMenu),
        });

        if (!updateResponse.ok) {
          const errorText = await updateResponse.text();
          throw new Error(`Erro ao atualizar Cardápio: ${errorText}`);
        }

        console.log('Cardápio atualizado com sucesso');
      } else {
        // Se não existir, criar um novo cardápio (POST)
        const novoMenu = { data: dataFormatada, pratos: pratosArray };

        const postResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/menu`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(novoMenu),
        });

        if (!postResponse.ok) {
          const errorText = await postResponse.text();
          throw new Error(`Erro ao adicionar Cardápio: ${errorText}`);
        }

        console.log('Cardápio adicionado com sucesso');
      }

      setPratosArray([]); // Limpar o array de pratos após adicionar
      setDataSelecionada(new Date()); // Limpar a data selecionada, se necessário
    } catch (error) {
      console.error('Erro ao adicionar/atualizar cardápio:', error);
    }
  };


  const handleAdicionarPrato = () => {
    if (nomePrato && descricaoPrato) {
      const novoPrato = { nome: nomePrato, descricao: descricaoPrato };
      setPratosArray([...pratosArray, novoPrato]);
      setNomePrato('');
      setDescricaoPrato('');
      setShowPratoList(false);
    } else {
      alert('Preencha o nome e a descrição do prato.');
    }
  };

  const handleExcluirPrato = (index) => {
    const novosPratos = [...pratosArray];
    novosPratos.splice(index, 1); // Remove o prato do array
    setPratosArray(novosPratos);
  };

  const handlePratoClick = (prato) => {
    setNomePrato(prato.nome);
    setDescricaoPrato(prato.descricao);
    setShowPratoList(false);
  };

  return (
    <div className="update-menu-screen">
      <div className="header">
        <img src={logoImage} alt="Logo" className="logo" />
        <button className="btn-back" onClick={onBack}>
          Ver cardápio
        </button>
      </div>

      <div className="container">
        <div className="form">
          <input
            type="text"
            placeholder="Nome do Prato"
            value={nomePrato}
            onChange={(e) => setNomePrato(e.target.value)}
            onFocus={() => setShowPratoList(true)}
          />
          {showPratoList && (
            <div className="">
              <ul className="pratos-list-container">
                {pratosDisponiveis.map((prato) => (
                  <li className="prato-item" key={prato._id} onClick={() => handlePratoClick(prato)}>
                    {prato.nome}
                  </li>
                ))}
              </ul>
            </div>
          )}
          <textarea
            placeholder="Descrição do Prato"
            value={descricaoPrato}
            onChange={(e) => setDescricaoPrato(e.target.value)}
          ></textarea>

          <button className="btn-add-lunch" onClick={handleAdicionarPrato}>Adicionar Prato ao Cardápio</button>

          <input
            type="date"
            value={dataSelecionada.toISOString().substring(0, 10)}
            onChange={(e) => setDataSelecionada(new Date(e.target.value))}
          />

          <div className="btn-enviar">
            <button className="btn-add-lunch" onClick={handleAdicionarMenu}>Salvar Cardápio</button>
          </div>
        </div>

        <div className="cardapios-list">
          <h3>Pratos Adicionados</h3>
          {pratosArray.map((prato, index) => (
            <ul key={prato._id} className="">
              <li key={index}>
                <span>{prato.nome}</span>
                <span className="descricao">{prato.descricao}</span>
                <FontAwesomeIcon
                  icon={faTrash}
                  className="delete-icon"
                  onClick={() => handleExcluirPrato(index)}
                />
              </li>

            </ul>
          ))}
        </div>

        {/* <div className="cardapios-list">
          <h3>Pratos Adicionados</h3>
          <ul>
            {pratosArray.map((prato, index) => (
              <li key={index}>
                <span>{prato.nome}</span>
                <span className="descricao">{prato.descricao}</span>
                <FontAwesomeIcon
                  icon={faTrash}
                  className="delete-icon"
                  onClick={() => handleExcluirPrato(index)}
                />
              </li>
            ))}
          </ul>
        </div> */}
      </div>
    </div>
  );
};

export default UpdateMenu;
